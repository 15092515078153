import Vuex from 'vuex';
import toastNotification from './toastNotification.store';
import modalComponent from './modalComponent.store';
import xmlValidator from '../../modules/xmlValidation/store/xmlValidator.store';

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    toastNotification,
    modalComponent,
    xmlValidator
  },
});

export default store;