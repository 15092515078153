
import {computed, defineComponent, PropType} from 'vue';
import FormToolTip from '../FormToolTip.vue';
import IconAlert from '../../assets/icons/icon-alert.vue';
import ToolTip from '../ToolTip.vue';
import {InputErrorOptions} from '../../models/coreComponents.model';

export default defineComponent({
  components: {
    FormToolTip,
    IconAlert,
    ToolTip,
  },
  props: {
    customDisabledMessage: {default: '', type: String},
    disabled: {default: false, type: Boolean},
    disabledLabel: {default: false, type: Boolean},
    error: {default: false, type: Boolean},
    errorOptions: {default: () => ({
      errorCount: 350,
      errorMessage: 'Input exceeded maximum character count',
      warningCount: 125,
      warningMessage: 'Not all text will be visible',
    }), type: Object as PropType<InputErrorOptions>
    },
    hasCharCount: {default: false, type: Boolean},
    id: {default: '', type: String},
    location: {default: '', type: String},
    locationStyle: {default: '', type: String},
    maxlength: {default: null},
    placeholder: {default: '', type: String},
    value: {default: '', type: String},
  },
  setup(props, context) {

    const disabledMessage = computed(() => {
      return props.customDisabledMessage ? props.customDisabledMessage : 'Cannot change this value';
    })

    const inputValue = computed({
      get(): string {
        return props.value;
      },
      set(event): void {
        context.emit('change');
        context.emit('input', event);
      },
    });

    const hasCharacters = computed(() => {
      if (props.value) {
        return props.value.length > 0
      }
      return false;
    });
    
    const hasError = computed(() => {
      if (props.value) {
        return props.error || props.value.length > props.errorOptions.errorCount;
      } else {
        return false;
      }
    });

    const hasWarning = computed(() => {
      if (props.value) {
        return props.error || props.value.length > props.errorOptions.warningCount;
      } else {
        return false;
      }
    });

    const disabledStyle = computed(() => {
      if (props.disabled && props.disabledLabel) {
        return {opacity: .5};
      }
      return {};
    });

    function blur() {
      context.emit('blur');
    };

    function enter() {
      context.emit('enter');
    };

    return {
      blur,
      disabledMessage,
      disabledStyle,
      enter,
      hasCharacters,
      hasError,
      hasWarning,
      inputValue,
    }
  },
})
