import {ModalParams} from '../models/modalParams.model';

const xmlStore = {
  state: {
    modalParams: {
      isActive: false,
      activeComponent: null,
      data: null
    }
  },
  actions: {},
  mutations: {
    open(state, {activeComponent, data = null}) {
      state.modalParams = {
        isActive: true,
        activeComponent,
        data
      } as ModalParams;
    },
    close(state)  {
      state.modalParams = {
        isActive: false,
        activeComponent: null,
        data: null
      } as ModalParams;
    }
  },
};

export default xmlStore;