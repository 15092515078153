<template>
  <main class='options-dropdown'>
    <drop-down direction='left' :horizontal-offset='10' :vertical-offset='12'>
      <template #clickable>
        <slot />
      </template>
      <template #main-actions>
        <a @click='signOut'>
          <img src='@/core/assets/icons/icon-logout.svg' alt='logout' class='icon'/>
          Sign Out
        </a>
      </template>
    </drop-down>
  </main>
</template>

<script lang='ts'>
import {defineComponent} from 'vue';
import DropDown from './DropDown.vue';

export default defineComponent({
  components: {
    DropDown,
  },
  setup() {

    function signOut() {
      console.log('Sign Out')
    }
    
    return {
      signOut,
    }
  },
})
</script>

<style scoped>
.options-dropdown {
  align-items: center;
  border-left: 1px solid rgba(0,0,0,.1);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  letter-spacing: .08em;
  text-transform: uppercase;
  width: 72px;
  z-index: 7;
}

a {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0 16px;
  width: 100%;
  transition: color .1s ease-in-out;
}

.icon {
  margin-right: 6px;
}

a:hover {
  color: var(--color-primary);
}

a:hover > img {
  opacity: 1;
}

img {
  opacity: .6;
  transition: opacity .1s ease-in-out;
}
</style>
