import {ShowToastParams, ToastNotificationState} from '../models/tostNotification.model';

const toastNotification = {
  namespaced: true,
  dynamic: true,
  state: {
    toastParams: {
      isActive: false,
      isError: false,
      isSuccess: false,
      message: '',
    }
  },
  getters: {

  },
  actions: {
    async showToastNotification(context, {message, isError, timeout, isSuccess}: ShowToastParams) {
      const timer = isError && !timeout ? 10000 : timeout;
      context.dispatch('removeToastNotificationTimer', timer);
      context.commit('showToastNotification', {
        isActive: true,
        message,
        isError,
        isSuccess,
      });
    },

    removeToastNotificationTimer(context, timer: number) {
      setTimeout(() => {
        context.commit('closeToastNotification');
      }, timer);
    },
  },
  mutations: {
    showToastNotification(state, params: ShowToastParams) {
      state.toastParams = params;
    },

    closeToastNotification(state) {
      state.toastParams = {
        isActive: false,
        isError: false,
        isSuccess: false,
        message: '',
      }
    },
  },
};

export default toastNotification;