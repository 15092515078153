
import {defineComponent, ref, onMounted} from 'vue';
import ModalBackdrop from '../../../core/components/ModalBackdrop.vue';
import {useStore} from 'vuex';

export default defineComponent({
  components: {
    ModalBackdrop,
  },
  props: {
  },
  setup(props, context) {
     const store = useStore();
     const isButtonDisabled = ref(false);
     const isFieldsValid = ref(true);
     const email = ref('');
     const password = ref('');
     const loginAndValidate = store.state.modalComponent.modalParams.data.loginAndValidate;
    async function login() {
      isButtonDisabled.value = true;
      isButtonDisabled.value = await loginAndValidate(email.value, password.value);
      isFieldsValid.value = false;
    }

    function closeModal() {
      store.commit('close');
    }
    return{
      closeModal,
      login,
      email,
      password,
      isButtonDisabled,
      isFieldsValid,
    };
  },
})
