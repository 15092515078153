
import {defineComponent} from 'vue';
import ModalBackdrop from '../../../core/components/ModalBackdrop.vue';
import {useStore} from 'vuex';

export default defineComponent({
  components: {
    ModalBackdrop,
  },
  setup(props, context) {
     const store = useStore();
     const url = store.state.xmlValidator.xmlValidatorParams.xmlUrl;
     const mailTo = 'support@wonderkind.com';
     const subject = 'New XML validated!';
     const mailContent = `Hi Team,%0D%0A %0D%0A The XML as shown below has been validated successfully and is ready to be added to its account. %0D%0A %0D%0A${url}`
    function closeModal() {
      store.commit('close')
    }
    return{
      closeModal,
      mailContent,
      mailTo,
      subject,
    };
  },
})
