
import {ref, defineComponent, markRaw, computed} from 'vue';
import XmlLoginModal from './modals/XmlLoginModal.vue';
import XmlSuccessModal from './modals/XmlSuccessModal.vue';
import XmlSummaryAndDetails from './components/XmlSummaryAndDetails.vue';
import {useStore} from 'vuex';
import axios from 'axios';

export default defineComponent({
  components: {XmlSummaryAndDetails},
  setup() {
    const store = useStore();
    const baseUrl = process.env.VUE_APP_BASE_URL;
    const isButtonDisabled = ref(false);
    const xmlData = computed(() => store.state.xmlValidator.xmlValidatorParams.xmlData);
    const modalComponent = store.state.modalComponent;
    function showNotification(message: string) {
      store.dispatch('toastNotification/showToastNotification',{
      isActive: true,
      isError: true,
      isSuccess: false,
      timeout: 5000,
      message,
    });
    }

    function updateXmlUrl(e) {
      store.commit('setXmlUrl', e.target.value);
    }

    function openXmlLoginModal() {
      store.commit('open', {activeComponent: markRaw(XmlLoginModal), data: {
        loginAndValidate: postXmlUrl,
      }});
    }

    function openXmlSuccessModal() {
        store.commit('open', {activeComponent: markRaw(XmlSuccessModal)});
    }

   async function postXmlUrl (login?: string, password?: string) {
     const xmlUrl = store.state.xmlValidator.xmlValidatorParams.xmlUrl;
      store.commit('setXmlData', null);
      const dataToValidate = login && password ? {
        url: xmlUrl,
        login,
        password
      } : { url: xmlUrl,}
      try {
        isButtonDisabled.value = true;
        const loginFailed = false;
        const response = await axios.post(`${baseUrl}/validate`, dataToValidate);
        isButtonDisabled.value = false;
        const xmlValidation = response.data;
        if (!xmlValidation.passedAuth.passed ) {
          if (modalComponent.modalParams.activeComponent) {
            showNotification(xmlValidation.passedAuth.message);
          } else {
            showNotification(xmlValidation.requiredAuth.message);
          }
          openXmlLoginModal()
          return loginFailed;
        }
        if (!xmlValidation.isXml.passed) {
           showNotification(xmlValidation.isXml.message);
           return;
        }
        if (!xmlValidation.structure.passed) {
          showNotification(xmlValidation.structure.message);
          store.commit('setXmlData', response.data);
          return;
        }
        if (!xmlValidation.fields.passed) {
          const invalidJobsCount = xmlValidation.fields.invalidJobCount;
          const message = invalidJobsCount ? `Some fields are incorrect. Total amount of invalid jobs: ${invalidJobsCount}` : 'Some fields are incorrect';
          showNotification(message);
        }
      store.commit('setXmlData', response.data);
        if (xmlValidation.success) {
          openXmlSuccessModal()
        }
      } catch(error) {
        const errorMessage = error.response.data.errors.url.toString();
        showNotification(errorMessage)
        isButtonDisabled.value = false;
      }
    }
    return {
      xmlData,
      openXmlLoginModal,
      openXmlSuccessModal,
      postXmlUrl,
      showNotification,
      updateXmlUrl,
      isButtonDisabled
    };
  },

})
