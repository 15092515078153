<template>
  <main>
    <h3>
      Link 3
    </h3>
  </main>
</template>

<script lang='ts'>
import {defineComponent} from 'vue';

export default defineComponent({
  components: {

  },
  setup() {

    return{

    };
  },

})
</script>

<style>

</style>