
import {defineComponent} from 'vue';
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';

export default defineComponent({
  components: {SshPre},
  props: {
    xmlData: {default: null, type: Object}
  },
  setup() {
    return {
    };
  },

})
