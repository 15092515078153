<template>
  <main>
    <section class='top'>
      <div class='wonderkind'>
        <router-link to='/' class='logo-opacity'>
          <img src='@/core/assets/wonderkind/developer-station-logo.svg' alt='logo' class='logo'/>
        </router-link>
      </div>
      <options-drop-down>
        <img src='@/core/assets/icons/icon-options.svg' alt='options' class='options'/>
      </options-drop-down>
    </section>
    
    <section class='navbar'>
      <nav>
        <router-link to='/xml-validator'>
          <div class='link'>
            <img src='@/core/assets/icons/xml-icon.svg' alt='company' class='icon'/>
            <p>XML validation</p>
          </div>
        </router-link>
        <!-- <router-link to='/link2'>
          <div class='link'>
            <img src='@/core/assets/icons/icon-white-edit.svg' alt='edit' class='icon'/>
            <p>Link 2</p>
          </div>
        </router-link>
        <router-link to='/link3'>
          <div class='link'>
            <img src='@/core/assets/icons/icon-white-folder.svg' alt='folder' class='icon'/>
            <p>Link 3</p>
          </div>
        </router-link> -->
      </nav>
    </section>
  </main>
</template>

<script lang='ts'>
import {defineComponent} from 'vue';
import OptionsDropDown from './OptionsDropDown.vue';

export default defineComponent({
  components: {
    OptionsDropDown,
  },

})
</script>

<style scoped>
.top {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  height: 72px;
  justify-content: flex-end;
  width: 100%;
}

.wonderkind {
  display: flex;
  position: relative;
  height: 100%;
  margin-right: auto;
  margin-left: 24px;
  padding-top: 1%;
}

.logo-opacity {
  position: absolute;
  top: 16px;
  align-items: baseline;
  opacity: 1;
}

h1 {
  font-size: 20px;
  margin-left: 10px;
  margin-top: 2%;
  text-transform: uppercase;
}

.logo {
  height: 50px;
  margin-top: -5px;
}

.options {
  height: 30px; 
}

.navbar {
  background-color: #08153E;;
  color: #ffffff;
  height: 48px;
  padding: 0px 140px 0px 140px;
  width: 100%;
}

.navbar nav {
  align-items: center;
  display: flex;
  height: 100%;
  margin: auto;
  width: var(--width-max-content);
}

.link {
  display: flex;
  height: 100%;
  padding: 8px 8px 8px 0;
  margin: auto;
  margin-right: 24px;
  width: 100%;
}

.link p {
  margin-top: 4px;
}

a {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  letter-spacing: 0.8px;
  opacity: .6;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity .2s ease-in-out;
}

.icon {
  height: 24px;
  margin-right: 4px;
  margin-top: 2px;
}

a.router-link-active {
  opacity: 1;
}

a:hover {
  opacity: 1;
}
</style>
