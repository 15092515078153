
import {defineComponent} from 'vue';

export default defineComponent({
  components: {

  },
  setup() {

    return{

    };
  },

})
