<template>
  <modal-backdrop>
    <section @click.stop>
      <div class="login-modal">
        <h3>Log in</h3>
        <img class="close" @click="closeModal" v-if="true" src="@/core/assets/icons/icon-close.svg" alt="">
        <div class="login-form">
          <label for="email">login</label>
          <input 
            v-model="email"
            type="text" 
            name="email" 
            class="email"
            :class="{'invalid-fields': !isFieldsValid}"
          />
          <label for="password">password</label>
          <input 
            v-model="password"
            type="password" 
            name="password" 
            class="password"
            :class="{'invalid-fields': !isFieldsValid}"
          />
          <default-button 
            @click="login" 
            class="submit" 
            :disabled="isButtonDisabled"
            :class="{'btn-disabled': isButtonDisabled}"
          >
            <span class="btn-text">{{true ? 'Submit' : 'Try again'}}</span>
          </default-button>
        </div>
      </div>
    </section>
  </modal-backdrop>
</template>

<script lang='ts'>
import {defineComponent, ref, onMounted} from 'vue';
import ModalBackdrop from '../../../core/components/ModalBackdrop.vue';
import {useStore} from 'vuex';

export default defineComponent({
  components: {
    ModalBackdrop,
  },
  props: {
  },
  setup(props, context) {
     const store = useStore();
     const isButtonDisabled = ref(false);
     const isFieldsValid = ref(true);
     const email = ref('');
     const password = ref('');
     const loginAndValidate = store.state.modalComponent.modalParams.data.loginAndValidate;
    async function login() {
      isButtonDisabled.value = true;
      isButtonDisabled.value = await loginAndValidate(email.value, password.value);
      isFieldsValid.value = false;
    }

    function closeModal() {
      store.commit('close');
    }
    return{
      closeModal,
      login,
      email,
      password,
      isButtonDisabled,
      isFieldsValid,
    };
  },
})
</script>

<style scoped>
h3 {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  padding: 33px 0;
}

.login-modal {
  position: relative;
  background-color: #ffffff;
  opacity: 1 !important;
  height: 357px;
  width:437px;
  text-transform: uppercase;
  border-radius: var(--border-radius);
  animation: bouncing 0.3s 1 linear;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.login-form {
  position: relative;
  width: fit-content;
  margin: auto;
}

label {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #08153E;
  letter-spacing: .1em;
  margin-bottom: 4px;
}

.email, .password {
  width: 330px;
  border-radius: var(--border-radius);
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #FCFCFC;
  outline: none;
  margin-bottom: 10px;
  padding: 14px 16px;
  font: inherit;
  font-size: 14px;
  line-height: 18px;
  color: rgba(8, 21, 62, 0.8);
  resize: none;
  transition: border .1s ease-in-out;
}

.submit {
  position: absolute;
  top: 170px;
  right: 121px;
  color: #ffffff;
  width: 81px;
  height: 39px;
}

.btn-disabled {
   pointer-events: none;
}

.invalid-fields {
   border: 1px solid red;
}

.submit .btn-text {
  position: absolute;
}

.submit:active {
  top: 171px;
  padding: 1px 18px;
  height: 38px;
  width: 80px;
  margin: 1px;
}
  
  @keyframes bouncing {
    0% { transform: scale(0); }
    15% { transform: scale(.5); }
    40% { transform: scale(1.05) }
    100% { transform: scale(1) }
  }
</style>