<template>
  <main class='tool-tip-container'>
    <div @mouseover='showToolTip = true' @mouseleave='showToolTip = false'>
      <slot name='hoverable'/>
    </div>
    <div
      v-show='!hideToolTip'
      class='tool-tip'
      ref='toolTip'
      :class='[{"tool-tip-animation" : showToolTip, "error" : hasError}, cornerLocation]'
      :style='{maxWidth: maxWidth ? maxWidth + "px" : "auto", width: width ? width + "px" : "auto", marginRight: locationStyle}'
    >
      <slot name='tool-tip'/>
    </div>
  </main>
</template>

<script lang='ts'>
import {defineComponent, onMounted, PropType, ref} from 'vue';
import {ToolTipCornerLocation} from '../models/coreComponents.model';

export default defineComponent({
  props: {
    cornerLocation: {default: 'bottom-right', type: String as PropType<ToolTipCornerLocation>},
    hasError: {default: false, type: Boolean},
    hideToolTip: {default: false, type: Boolean},
    horizontalOffset: {default: 0, type: Number},
    locationStyle: {default: '', type: String},
    maxWidth: {default: 0, type: Number},
    verticalOffset: {default: 0, type: Number},
    width: {default: 0, type: Number},
  },
  setup(props, context) {
    const toolTip = ref<HTMLElement | null>(null);
    const showToolTip = ref<boolean>(false);
    
    onMounted(() => {
      if (toolTip.value) {
        toolTip.value.style.bottom = props.verticalOffset + 'px';
        toolTip.value.style.right = props.horizontalOffset + 'px';
      };
    });

    return {
      showToolTip,
      toolTip,
    };
  },

})
</script>

<style scoped>
.tool-tip-container {
  position: relative;
}

.tool-tip {
  background-color: var(--color-primary);
  border-radius: var(--border-radius);
  color: white;
  font-size: 14px;
  font-weight: 600;
  max-width: 320px;
  min-width: 150px;
  padding: 4px 8px;
  position: absolute;
  transition: background-color 0.2s ease-in-out;
  visibility: hidden;
}

.tool-tip-animation {
  animation-duration: 0.2s;
  animation-name: slideIn;
  visibility: visible;
}

.tool-tip::after {
  bottom: 0;
  border: 5px solid transparent;
  border-bottom: 0;
  border-top-color: black;
  content: "";
  margin-bottom: -5px;
  margin-left: -8px;
  position: absolute;
  right: 12px;
  transition: border 0.2s ease-in-out;
}

.tool-tip.top-center::after, .tool-tip.top-left::after, .tool-tip.top-right::after {
  top: 0;
  bottom: auto;
  margin-top: -10px;
  border: 5px solid transparent;
  border-bottom-color: black;
}

.tool-tip.bottom-right::after {
  right: 12px;
}

.tool-tip.bottom-left::after {
  right: auto;
  left: 12px;
}

.tool-tip.bottom-center::after {
  right: calc(50% - 4px);
}

.tool-tip.top-right::after {
  left: auto;
  right: 12px;
}

.tool-tip.top-left::after {
  right: auto;
  left: 12px;
}

.tool-tip.top-center::after {
  right: calc(50% - 4px);
}

.error.tool-tip {
  background-color: var(--color-warning);
}

.error.tool-tip::after {
  border-top-color: var(--color-warning);
}

@keyframes slideIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}
</style>