<template>
  <main class="main-component-wrapper">
    <h2>XML-feed
      <span class="xml-failed"  v-if="xmlData && !xmlData.success">didn't pass 
        <img src="@/core/assets/icons/cross-in-red-circle.svg" alt="">
      </span>
    </h2>
    <xml-summary-and-details :xmlData="xmlData"></xml-summary-and-details>
    <div class="xml-form">
      <label for="xml-url">xml url</label>
      <input 
        type="text" 
        name="xml-url"
        @change="(e) => updateXmlUrl(e)"
        class="xml-input" 
        :placeholder="'Paste your URL...'"
      />
      <default-button 
        @click="postXmlUrl" 
        class="xml-submit" 
        :disabled="isButtonDisabled"
        :class="{'btn-disabled': isButtonDisabled}"
      >
        <span class="btn-text">{{xmlData && !xmlData.success ? 'Try again' : 'Submit'}}</span>
      </default-button>
    </div>
  </main>
</template>

<script lang='ts'>
import {ref, defineComponent, markRaw, computed} from 'vue';
import XmlLoginModal from './modals/XmlLoginModal.vue';
import XmlSuccessModal from './modals/XmlSuccessModal.vue';
import XmlSummaryAndDetails from './components/XmlSummaryAndDetails.vue';
import {useStore} from 'vuex';
import axios from 'axios';

export default defineComponent({
  components: {XmlSummaryAndDetails},
  setup() {
    const store = useStore();
    const baseUrl = process.env.VUE_APP_BASE_URL;
    const isButtonDisabled = ref(false);
    const xmlData = computed(() => store.state.xmlValidator.xmlValidatorParams.xmlData);
    const modalComponent = store.state.modalComponent;
    function showNotification(message: string) {
      store.dispatch('toastNotification/showToastNotification',{
      isActive: true,
      isError: true,
      isSuccess: false,
      timeout: 5000,
      message,
    });
    }

    function updateXmlUrl(e) {
      store.commit('setXmlUrl', e.target.value);
    }

    function openXmlLoginModal() {
      store.commit('open', {activeComponent: markRaw(XmlLoginModal), data: {
        loginAndValidate: postXmlUrl,
      }});
    }

    function openXmlSuccessModal() {
        store.commit('open', {activeComponent: markRaw(XmlSuccessModal)});
    }

   async function postXmlUrl (login?: string, password?: string) {
     const xmlUrl = store.state.xmlValidator.xmlValidatorParams.xmlUrl;
      store.commit('setXmlData', null);
      const dataToValidate = login && password ? {
        url: xmlUrl,
        login,
        password
      } : { url: xmlUrl,}
      try {
        isButtonDisabled.value = true;
        const loginFailed = false;
        const response = await axios.post(`${baseUrl}/validate`, dataToValidate);
        isButtonDisabled.value = false;
        const xmlValidation = response.data;
        if (!xmlValidation.passedAuth.passed ) {
          if (modalComponent.modalParams.activeComponent) {
            showNotification(xmlValidation.passedAuth.message);
          } else {
            showNotification(xmlValidation.requiredAuth.message);
          }
          openXmlLoginModal()
          return loginFailed;
        }
        if (!xmlValidation.isXml.passed) {
           showNotification(xmlValidation.isXml.message);
           return;
        }
        if (!xmlValidation.structure.passed) {
          showNotification(xmlValidation.structure.message);
          store.commit('setXmlData', response.data);
          return;
        }
        if (!xmlValidation.fields.passed) {
          const invalidJobsCount = xmlValidation.fields.invalidJobCount;
          const message = invalidJobsCount ? `Some fields are incorrect. Total amount of invalid jobs: ${invalidJobsCount}` : 'Some fields are incorrect';
          showNotification(message);
        }
      store.commit('setXmlData', response.data);
        if (xmlValidation.success) {
          openXmlSuccessModal()
        }
      } catch(error) {
        const errorMessage = error.response.data.errors.url.toString();
        showNotification(errorMessage)
        isButtonDisabled.value = false;
      }
    }
    return {
      xmlData,
      openXmlLoginModal,
      openXmlSuccessModal,
      postXmlUrl,
      showNotification,
      updateXmlUrl,
      isButtonDisabled
    };
  },

})
</script>

<style scoped>
main {
  text-transform: uppercase;
  max-width: 925px;
}

h2 {
  color: #08153E;
  font-size: 22px;
  margin-top: 60px;
  margin-bottom: 29px;
  font-weight: 600;

}

h3 {
  font-size: 14px;
  color: #08153E;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 5px;
}

.xml-failed {
  position: relative;
  text-transform: none;
}

.xml-failed img {
  position: absolute;
  top: 9px;
  right: -20px;
}

.summary-section, .details-section {
  color: #08153E;
}

.details-section {
  margin-bottom: 40px;
}

.details-section h3 {
  margin-bottom: 10px;
}

.summary, .details {
  display: grid;
  grid-template-columns: [first] 30% [line2] 30% [line3] 40%;
  height: 56px;
  background-color: #ffffff;
  border-radius: var(--border-radius);
  border: 1px solid rgba(8, 21, 62, 0.05);
  line-height: 56px;
  padding-left: 20px ;
  margin-bottom: 5px ;
}

.summary .status, .details .status {
  position: relative;
}

.status-faild {
  position: absolute;
  top: 9px;
  left: -14px;
}

.col-names {
  display: grid;
  grid-template-columns: [first] 30% [line2] 30% [line3] 40%;
}

.col-names div {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.field {
  font-size: 14px;
  font-weight: 600;
} 

.message {
  font-size: 10px;
  text-transform: none;
}

.preview {
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  color: #ffffff;
  font-size: 14px;
  background-color: #282923;
  border-radius: var(--border-radius);
  margin-bottom: 30px;
  padding: 20px;
}

.xml-form {
  position: relative;
}

.xml-input {
  position: relative;
  width: 88%;
  margin-bottom: 50px;
  border-radius: var(--border-radius);
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #FCFCFC;
  outline: none;
  padding: 14px 16px;
  font: inherit;
  font-size: 14px;
  line-height: 18px;
  color: rgba(8, 21, 62, 0.8);
  resize: none;
  transition: border .1s ease-in-out;
}

.xml-submit {
  position: absolute;
  top: 25px;
  right: 1px;
  color: #ffffff;
  width: 81px;
  height: 39px;
}

.xml-submit .btn-text {
  position: absolute;
}

.xml-submit:active {
  top: 26px;
  padding: 1px 18px;
  height: 38px;
  width: 80px;
  margin: 1px;
}

.btn-disabled {
   pointer-events: none;
}

.xml-input:focus {
  border: 1px solid rgba(0,0,0,0.2);
}

.xml-input::placeholder {
  color: rgba(93, 100, 122, .5);
}

label {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #08153E;
  width: 100%;
  letter-spacing: .1em;
  margin-bottom: 4px;
}
</style>