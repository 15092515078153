
import {defineComponent} from 'vue';
import {useStore} from 'vuex';
import Navigation from './core/components/Navigation.vue';
import ToastNotification from './core/components/ToastNotification.vue';

export default defineComponent({
  components: {
    Navigation,
    ToastNotification,
  },
  setup() {
    const store = useStore();
    const toastNotificationState = store.state.toastNotification;
    const modalComponent = store.state.modalComponent;
    
    return {
      toastNotificationState,
      modalComponent,
    };
  },
})
