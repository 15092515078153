<template>
  <main class='input' :class='{"error" : hasError, "disabled" : disabled}'>
    <form-tool-tip
      :has-error='hasError'
      :horizontal-offset='-9'
      :vertical-offset='30'
      :hide-tool-tip='!hasWarning || !hasCharacters'
      :corner-location='location'
      :location-style='locationStyle'
    >
      <template #hoverable>
        <label :for='id' :style='disabledStyle'>
          <slot />
          <span v-if='hasCharCount' class='char-count'>{{value.length}}</span>
          <icon-alert v-if='hasCharacters && hasWarning' class='alert' :color='hasError ? "var(--color-warning)" : "var(--color-primary)"'/>
        </label>
      </template>
      <template #tool-tip>
        <div>{{warningMessage}}</div>
      </template>
    </form-tool-tip>
    <input 
      v-model='inputValue'
      :disabled='disabled'
      :type='type'
      :style='{backgroundColor: fill}'
      :ref='id'
      :placeholder='placeholder'
      :id='id'
      :maxlength='maxlength'
      @keyup.enter='enter'
      @blur='blur'
    >
    <!-- emoji select goes here (need to look more into the package we are using) -->
    <tool-tip class='disabled-icon' v-if='disabled' horizaontalOffset='9' verticalOffset='36'>
      <template #hoverable>
        <img src='@/core/assets/icons/icon-lock.svg' alt='lock'/>
      </template>
      <template #tool-tip>
        {{disabledMessage}}
      </template>
    </tool-tip>
  </main>
</template>

<script lang='ts'>
import {computed, defineComponent, PropType} from 'vue';
import FormToolTip from '../FormToolTip.vue';
import IconAlert from '../../assets/icons/icon-alert.vue';
import ToolTip from '../ToolTip.vue';
import {InputErrorOptions} from '../../models/coreComponents.model';

export default defineComponent({
  components: {
    FormToolTip,
    IconAlert,
    ToolTip,
  },
  props: {
    customDisabledMessage: {default: '', type: String},
    disabled: {default: false, type: Boolean},
    disabledLabel: {default: false, type: Boolean},
    error: {default: false, type: Boolean},
    errorOptions: {default: () => ({
      errorCount: 350,
      errorMessage: 'Input exceeded maximum character count',
      warningCount: 125,
      warningMessage: 'Not all text will be visible',
    }), type: Object as PropType<InputErrorOptions>
    },
    hasCharCount: {default: false, type: Boolean},
    id: {default: '', type: String},
    location: {default: '', type: String},
    locationStyle: {default: '', type: String},
    maxlength: {default: null},
    placeholder: {default: '', type: String},
    value: {default: '', type: String},
  },
  setup(props, context) {

    const disabledMessage = computed(() => {
      return props.customDisabledMessage ? props.customDisabledMessage : 'Cannot change this value';
    })

    const inputValue = computed({
      get(): string {
        return props.value;
      },
      set(event): void {
        context.emit('change');
        context.emit('input', event);
      },
    });

    const hasCharacters = computed(() => {
      if (props.value) {
        return props.value.length > 0
      }
      return false;
    });
    
    const hasError = computed(() => {
      if (props.value) {
        return props.error || props.value.length > props.errorOptions.errorCount;
      } else {
        return false;
      }
    });

    const hasWarning = computed(() => {
      if (props.value) {
        return props.error || props.value.length > props.errorOptions.warningCount;
      } else {
        return false;
      }
    });

    const disabledStyle = computed(() => {
      if (props.disabled && props.disabledLabel) {
        return {opacity: .5};
      }
      return {};
    });

    function blur() {
      context.emit('blur');
    };

    function enter() {
      context.emit('enter');
    };

    return {
      blur,
      disabledMessage,
      disabledStyle,
      enter,
      hasCharacters,
      hasError,
      hasWarning,
      inputValue,
    }
  },
})
</script>

<style scoped>
.input {
  position: relative;
}

input {
  border-radius: var(--border-radius);
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #FCFCFC;
  width: 100%;
  outline: none;
  padding: 14px 16px;
  font: inherit;
  font-size: 14px;
  line-height: 18px;
  color: rgba(8, 21, 62, 0.8);
  resize: none;
  transition: border .1s ease-in-out;
}

input:focus {
  border: 1px solid rgba(0,0,0,0.2);
}

input::placeholder {
  color: rgba(93, 100, 122, .5);
}

label {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #08153E;
  width: 100%;
  letter-spacing: .1em;
  margin-bottom: 4px;
}

.error input {
  border: 1px solid var(--color-warning);
}

.error label {
  color: var(--color-warning);
}

.char-count {
  margin-left: auto;
}

.alert {
  margin-left: 4px;
  width: 18px;
}

.disabled-icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: #004ddd;
}
</style>
