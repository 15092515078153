<template>
  <main class='tool-tip-container'>
    <div @mouseover='showToolTip = true' @mouseleave='showToolTip = false'>
      <slot name='hoverable'/>
    </div>
    <div class='tool' ref='toolTip' :class='{"tool-tip-animation" : showToolTip}'>
      <slot name='tool-tip'/>
    </div>
  </main>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';

export default defineComponent({
  props: {
    horizontalOffset: {default: 0, type: Number},
    verticalOffset: {default: 0, type: Number},
  },
  setup(props) {
    let showToolTip = ref<boolean>(false);
    const toolTip = ref<HTMLElement | null>(null);
    
    onMounted(() => {
      toolTip.value!.style.bottom = props.verticalOffset + 'px';
      const horizontalOffset = props.horizontalOffset - (toolTip.value!.clientWidth / 2);
      toolTip.value!.style.left = horizontalOffset + 'px';
    })

    return {
      showToolTip,
      toolTip,
    };
  },
})
</script>

<style scoped>
.tool-tip-container {
  position: relative;
}

.tool-tip {
  background-color: var(--color-primary);
  border-radius: var(--border-radius);
  color: white;
  font-size: 14px;
  font-weight: 600;
  min-width: 150px;
  padding: 4px 8px;
  position: absolute;
  visibility: hidden;
}

.tool-tip-animation {
  animation-name: slideIn;
  animation-duration: 0.2s;
  visibility: visible;
}

.tool-tip::after {
  border: 5px solid transparent;
  border-bottom: 0;
  border-top-color: var(--color-primary);
  bottom: 0;
  content: '';
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  position: absolute;
}

@keyframes slideIn {
  0% {
      opacity: 0;
      transform: translateY(-1rem);
  }
  100% {
      opacity: 1;
      transform:translateY(0rem);
  }
}
</style>