import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import XmlValidator from './modules/xmlValidation';
import Link2 from './modules/module2';
import Link3 from './modules/module3';

const routes: Array<RouteRecordRaw> = [
  { 
    path: '/',
    redirect: '/xml-validator',
  },
  ...XmlValidator,
  ...Link2,
  ...Link3,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
