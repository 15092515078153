<template>
  <main v-if="xmlData" class="main-component-wrapper">
    <section class="summary-section">
      <h3>Summary</h3>
      <div class="summary">
        <div class="field">XML</div>
        <div class="status">
          <img v-if="xmlData.isXml" src="@/core/assets/icons/check-icon.svg" alt="">
          <img class="status-faild" v-if="!xmlData.isXml" src="@/core/assets/icons/cross-icon.svg" alt="">
        </div>
        <div v-if="xmlData.message" class="message">{{xmlData.message}}</div>
      </div>
      <div class="summary">
        <div class="field">Structure</div>
        <div class="status">
          <img v-if="xmlData.structure.passed" src="@/core/assets/icons/check-icon.svg" alt="">
          <img class="status-faild" v-if="!xmlData.structure.passed" src="@/core/assets/icons/cross-icon.svg" alt="">
        </div>
        <div v-if="xmlData.structure.message" class="message">{{xmlData.message}}</div>
      </div>
      <div class="summary">
        <div class="field">Content</div>
        <div class="status">
          <img v-if="xmlData.success" src="@/core/assets/icons/check-icon.svg" alt="">
          <img class="status-faild" v-if="!xmlData.success" src="@/core/assets/icons/cross-icon.svg" alt="">
        </div>
        <div v-if="xmlData.structure.message" class="message">{{xmlData.message}}</div>
      </div>
    </section>
    <section v-if="xmlData && xmlData.fields" class="details-section">
      <h3>Details</h3>
      <div class="col-names">
        <div class="col-field">field</div>
        <div class="col-status">status</div>
        <div class="col-message">message</div>
      </div>
      <div class="details" :key="index" v-for="field, index in xmlData.fields.details.items">
        <div class="field">{{field.name}}</div>
        <div class="status">
          <img v-if="field.passed" src="@/core/assets/icons/check-icon.svg" alt="">
          <img class="status-faild" v-if="!field.passed" src="@/core/assets/icons/cross-icon.svg" alt="">
        </div>
        <div v-if="field.message" class="message">{{field.message}}</div>
      </div>
    </section>
    <section v-if="xmlData && xmlData.fields" class="preview-section">
      <h3>Preview</h3>
      <ssh-pre class="preview" language="xml">
        {{xmlData.fields.details.preview}}
      </ssh-pre>
    </section>
    <section v-if="xmlData && xmlData.structure.details" class="preview-section">
      <h3>Valid Example</h3>
      <ssh-pre v-if="xmlData.structure.details" class="preview" language="xml">
        {{xmlData.structure.details.validExample}}
      </ssh-pre>
    </section>
  </main>
</template>

<script lang='ts'>
import {defineComponent} from 'vue';
import SshPre from 'simple-syntax-highlighter';
import 'simple-syntax-highlighter/dist/sshpre.css';

export default defineComponent({
  components: {SshPre},
  props: {
    xmlData: {default: null, type: Object}
  },
  setup() {
    return {
    };
  },

})
</script>

<style scoped>
main {
  text-transform: uppercase;
  max-width: 925px;
}


h3 {
  font-size: 14px;
  color: #08153E;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 5px;
}

.summary-section, .details-section {
  color: #08153E;
}

.details-section {
  margin-bottom: 40px;
}

.details-section h3 {
  margin-bottom: 10px;
}

.summary, .details {
  display: grid;
  grid-template-columns: [first] 30% [line2] 30% [line3] 40%;
  height: 56px;
  background-color: #ffffff;
  border-radius: var(--border-radius);
  border: 1px solid rgba(8, 21, 62, 0.05);
  line-height: 56px;
  padding-left: 20px ;
  margin-bottom: 5px ;
}

.summary .status, .details .status {
  position: relative;
}

.status-faild {
  position: absolute;
  top: 9px;
  left: -14px;
}

.col-names {
  display: grid;
  grid-template-columns: [first] 30% [line2] 30% [line3] 40%;
}

.col-names div {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.field {
  font-size: 14px;
  font-weight: 600;
} 

.message {
  font-size: 10px;
  text-transform: none;
}

.preview {
  height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  color: #ffffff;
  font-size: 14px;
  background-color: #282923;
  border-radius: var(--border-radius);
  margin-bottom: 30px;
  padding: 20px;
  text-transform: none;
}
</style>
<style>
.ssh-pre[data-type="xml"] .tag-name {color: #F92472;}
</style>