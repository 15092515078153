<template>
  <div class='backdrop'>
    <svg>
      <g transform="rotate(-45)" class="falling">
        <rect
          x="-20%"
          y="1%"
          width="4"
          ry="2"
          fill="url(#starGradient)"
          class="star"
        />
        <rect
          x="20%"
          y="30%"
          width="4"
          ry="2"
          fill="url(#starGradient)"
          class="star"
        />
        <rect
          x="48%"
          y="50%"
          width="4"
          ry="2"
          fill="url(#starGradient)"
          class="star"
        />
      </g>

      <g class="still">
        <ellipse v-for="(star, index) in stars"
          class="star"
          :key="index"
          :cy="star.y"
          :cx="star.x"
          :rx="star.size"
          :ry="star.size"
        />
      </g>

      <!-- svg styling -->
      <defs>
        <linearGradient id="starGradient" gradientTransform="rotate(90)">
          <stop offset="5%" stop-color="white" stop-opacity="0"/>
          <stop offset="100%" stop-color="white" stop-opacity="1"/>
        </linearGradient>
      </defs>
    </svg>

    <slot />
  </div>
</template>

<script lang='ts'>
import {defineComponent} from 'vue';

export default defineComponent({
  setup() {
    const starCount = 30; 

    function stars() {
      return [...Array(starCount)].map((e) => ({
        y: `${Math.random() * 100}%`,
        x: `${Math.random() * 100}%`,
        size: Math.floor(Math.random() * 2) + 1,
      }));
    }

    return {
      stars,
    };
  },
})
</script>

<style scoped>
.backdrop {
  background-color: rgba(8, 21, 62, 0.8);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.still .star {
  animation: fade .8s ease-in-out infinite alternate;
  fill: #ffffff;
}

.still .star:nth-of-type(5n+1) { animation-delay: .16s; }
.still .star:nth-of-type(5n+2) { animation-delay: .32s; }
.still .star:nth-of-type(5n+3) { animation-delay: .48s; }
.still .star:nth-of-type(5n+4) { animation-delay: .64s; }

.falling .star {
  animation: fall 4s ease-in-out infinite;
  height: 30px;
  opacity: .2;
  position: absolute;
}

.falling .star:nth-child(2) {
  animation-delay: 2.3s;
}

.falling .star:nth-child(3) {
  animation-delay: 1.4s;
}

@keyframes fade {
  0% { opacity: .2; }
  100% { opacity: .7; }
}

@keyframes fall {
  0% {
    height: 20px;
    transform: translateY(-10vh);
  }
  40% {
    height: 500px;
    transform: translateY(120vh);
  }
  50% {
    height: 100px;
    transform: translateY(150vh);
  }
  100% {
    height: 100px;
    transform: translateY(150vh);
  }
  }
</style>