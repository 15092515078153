<template>
  <button @click='click' :class='{"disabled" : disabled}'>
    <slot>Confirm</slot>
  </button>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  props: {
    disabled: {default: false, required: false, type: Boolean}
  },
  setup(props, context) {
    
    function click() {
      if (!props.disabled) {
        context.emit('activate');
      };
    }

    return {
      click,
    };
  },
})
</script>

<style scoped>
button {
  display: flex;
  align-items: center;;
  background-color: var(--color-primary);
  border: 1px solid rgba(0,0,0,0.04);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  font: inherit;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  letter-spacing: .04em;
  outline: none;
  padding: 0 16px;
  text-transform: uppercase;
}

button.disabled {
  cursor: not-allowed;
  opacity: .5;
}
</style>