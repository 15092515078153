<template>
  <svg class="alert-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 14H11V10H13V14ZM13 18H11V16H13V18ZM1.86902 19.499C1.48306 20.1656 1.96411 21 2.73445 21H21.2656C22.0359 21 22.5169 20.1656 22.131 19.499L12.8654 3.49482C12.4803 2.82954 11.5197 2.82954 11.1346 3.49483L1.86902 19.499Z" :fill="color"/>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  props: {
    color: {default: '#000000', type: String},
  },
})
</script>

<style scoped>
  .alert-icon {
    object-fit: none;
    height: 100%;
    width: 100%;
  }
</style>