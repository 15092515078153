<template>
  <main ref='main' class='main'>
    <section class='clickable' @click='open'>
      <slot name='clickable'/>
    </section>
    <section class='drop-down-container'>
      <div
        ref='container'
        v-if='opened'
        class='dropdown'
        :style='[positioningStyle, verticalPositioning === "top" ? {"bottom" : "40px"} : ""]'
        :class='{
          left: isLeft,
          right: isRight,
          "dropdown-arrow-bottom" : verticalPositioning === "top",
          "dropdown-arrow-top" : verticalPositioning === "bottom",
        }'
      >
        <div class='main-actions'>
          <slot name='main-actions'/>
        </div>
        <div class='secondary-actions'>
          <slot name='secondary-actions'/>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang='ts'>
import {computed, defineComponent, PropType, ref, toRefs, watch} from 'vue';
import {useRoute} from 'vue-router';

interface Clickable {
  click: () => void;
}

export default defineComponent({
  props: {
    direction: {default: 'right', type: String as PropType<'left' | 'right'>},
    horizontalOffset: {default: 0, type: Number},
    shouldClose: {default: false, type: Boolean},
    verticalOffset: {default: 0, type: Number},
    // Unsure if verticalPositioning is typed properly but this was able to remove the errors --> need to revisit later to confirm
    verticalPositioning: {default: null, type: String as PropType<'top' | 'bottom' | null>},
  },
  setup(props, context) {
    let opened = ref(false);
    const main = ref<HTMLElement | null>(null);
    const container = ref<HTMLElement | null>(null);
    const route = useRoute();
    const shouldClose = toRefs(props).shouldClose

    const isLeft = computed<boolean>(() => {
      return props.direction === 'left';
    });

    const isRight = computed<boolean>(() => {
      return props.direction === 'right';
    });

    const positioningStyle = computed(() => {
      if (props.direction === 'left') {
        if (props.verticalPositioning === 'top') {
          return {right: props.horizontalOffset + 'px'};
        }
        return {
          top: props.verticalOffset + 'px',
          right: props.horizontalOffset + 'px',
        };
      } else {
        return {
          top: props.verticalOffset + 'px',
          right: props.horizontalOffset + 'px',
        };
      }
    });

    function open() {
      opened.value = true;
      const firstDocumentElement = document.firstElementChild as unknown as Clickable;
      firstDocumentElement.click();
      document.addEventListener('click', clickedOutsideDropDown!);
    };

    function close() {
      opened.value = false;
      document.removeEventListener('click', clickedOutsideDropDown!);
    };

    function clickedOutsideDropDown(event: any) {
      if (!main.value!.contains(event.target)) {
        close();
      }
    };
    
    watch(route, () => {
      close();
    });

    watch(shouldClose, () => {
      if (props.shouldClose) {
        close();
      }
    });

    return {
      isLeft,
      isRight,
      main,
      open,
      opened,
      positioningStyle,
    };
  },
})
</script>

<style scoped>
.clickable {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    user-select: none;
}

.clickable:hover {
    opacity: .8;
}

.drop-down-container {
    position: relative;
}

.dropdown {
  animation-duration: 0.2s;
  animation-name: slideIn;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  color: #5D647A;
  position: absolute;
  white-space: nowrap;
  z-index: 2;
}

.dropdown img {
  margin-right: 8px;
}

.dropdown svg {
  fill: #5D647A;
  margin-right: 8px;
  opacity: .6;
  width: 16px;
}

.dropdown-arrow-top::after {
  border: 8px solid transparent;
  border-bottom-color: white;
  border-top: 0;
  content: '';
  filter : drop-shadow(0px -2px 1px rgba(130,130,130,0.1));
  margin-top: -8px;
  position: absolute;
  top: 0;
}

.dropdown-arrow-bottom::after {
  border: 8px solid transparent;
  border-bottom: 0;
  border-top-color: white;
  bottom: 0;
  content: '';
  filter : drop-shadow(0px 2px 1px rgba(130,130,130,0.1));
  margin-bottom: -8px;
  position: absolute;
}

.left::after {
  right: 24px;
}

.right::after {
  left: 24px;
}

.bottom {
  bottom: 40px;
  top:none;
}

.secondary-actions {
  background: rgba(179, 192, 216, 0.04);
  border-top: rgba(179, 192, 216, 0.1) solid 1px;
  padding-top: 8px;
}

.main-actions , .secondary-actions {
  align-items: flex-start;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 8px;
}

.main-actions > div, .secondary-actions > div {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.08em;
  padding: 8px 56px 8px 20px;
  text-transform: uppercase;
}

.main-actions > div > :first-child,
.secondary-actions > div > :first-child {
  flex: 0 0 40px;
}

.main-actions > div:hover, .secondary-actions > div:hover {
  color: #08153E;
}

.main-actions > a:hover > svg {
  opacity: 1;
}
</style>
