
import {defineComponent} from 'vue';
import DropDown from './DropDown.vue';

export default defineComponent({
  components: {
    DropDown,
  },
  setup() {

    function signOut() {
      console.log('Sign Out')
    }
    
    return {
      signOut,
    }
  },
})
