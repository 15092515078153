
import {defineComponent} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const notificationState = store.state.toastNotification.toastParams;

    function closeNotification() {
      store.commit('toastNotification/closeToastNotification');
    };
  return {
    closeNotification,
    notificationState,
  };
  },
})
