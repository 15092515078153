<template>
  <main id='app'>
    <section>
      <navigation class='navigation'/>
      <toast-notification v-if='toastNotificationState.toastParams.isActive'/>
      <transition>
        <component class="modal" :is="modalComponent.modalParams.activeComponent" v-if="modalComponent.modalParams.isActive"/>
      </transition>
      <router-view />
    </section>
  </main>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useStore} from 'vuex';
import Navigation from './core/components/Navigation.vue';
import ToastNotification from './core/components/ToastNotification.vue';

export default defineComponent({
  components: {
    Navigation,
    ToastNotification,
  },
  setup() {
    const store = useStore();
    const toastNotificationState = store.state.toastNotification;
    const modalComponent = store.state.modalComponent;
    
    return {
      toastNotificationState,
      modalComponent,
    };
  },
})
</script>

<style>
#app {
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  height: 100vh;
  min-width: 964px;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-component-wrapper {
  width: fit-content;
  min-width: 925px;
  margin: auto;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
</style>

<style scoped>
.navigation {
  top: 0;
  width: 100%;
  z-index: 3;
}

.modal {
  background-color: #707471;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}
</style>
