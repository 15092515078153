<template>
  <modal-backdrop class='modal-backdrop'>
    <section class="success-modal" @click.stop>
      <h1>XML-feed passed
        <img v-if="true" src="@/core/assets/icons/check-in-green-circle.svg" alt="">
      </h1>
      <h2>Congratulations!</h2>
      <img class="close" @click="closeModal" v-if="true" src="@/core/assets/icons/icon-close.svg" alt="">
      <span>You are ready to launch
        <a class="mailto" :href="`mailto:${mailTo}?subject=${subject}&body=${mailContent}`">
          <img v-if="true" src="@/core/assets/icons/mailto.svg" alt="">
          Send XML directly to Wonderkind.
        </a> 
      </span>
    </section>
  </modal-backdrop>
</template>

<script lang='ts'>
import {defineComponent} from 'vue';
import ModalBackdrop from '../../../core/components/ModalBackdrop.vue';
import {useStore} from 'vuex';

export default defineComponent({
  components: {
    ModalBackdrop,
  },
  setup(props, context) {
     const store = useStore();
     const url = store.state.xmlValidator.xmlValidatorParams.xmlUrl;
     const mailTo = 'support@wonderkind.com';
     const subject = 'New XML validated!';
     const mailContent = `Hi Team,%0D%0A %0D%0A The XML as shown below has been validated successfully and is ready to be added to its account. %0D%0A %0D%0A${url}`
    function closeModal() {
      store.commit('close')
    }
    return{
      closeModal,
      mailContent,
      mailTo,
      subject,
    };
  },
})
</script>

<style scoped>
h1 {
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  font-weight: 600;
  text-transform: none;
}

h2 {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
}

span {
  position: relative;
  font-size: 19px;
  text-align: center;
  font-weight: 600;
}

.mailto {
  display: flex;
  line-height: 19px;
  position: absolute;
  left: 270px;
  font-size: 10px;
  text-transform: none;
  cursor: pointer;
  text-decoration: unset;
  color: inherit;
}

.mailto:hover {
  text-decoration: underline;
}

.mailto:active, .mailto:visited {
  color: inherit;
}

.mailto img {
  height: 15px;
  margin-right: 3px;
}

.success-modal {
  position: relative;
  background-color: #ffffff;
  opacity: 1 !important;
  height: 294px;
  width: 725px;
  text-transform: uppercase;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  animation: bouncing 0.3s 1 linear;
}

@keyframes bouncing {
  0% { transform: scale(0); }
  15% { transform: scale(.5); }
  40% { transform: scale(1.05) }
  100% { transform: scale(1) }
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
</style>