const xmlValidator = {
  state: {
    xmlValidatorParams: {
      xmlData: null,
      xmlUrl: '',
    }
  },
  getters: {

  },
  actions: {},
  mutations: {
    setXmlUrl(state, xmlUrl) {
      state.xmlValidatorParams.xmlUrl = xmlUrl; 
    },

    setXmlData(state, xmlData) {
      state.xmlValidatorParams.xmlData = xmlData; 
    }
  },
};

export default xmlValidator;