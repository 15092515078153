<template>
  <main class='notification-wrapper'>
    <div
      @click='closeNotification'
      class='notification'
      :class='{
        error: notificationState.isError,
        success: notificationState.isSuccess,  
      }'
    >
      {{notificationState.message}} 
      <img 
        v-if='notificationState.isError' 
        src='@/core/assets/icons/icon-white-close.svg' 
        alt='close' 
        class='close-icon'
      />
    </div>
  </main>
</template>

<script lang='ts'>
import {defineComponent} from 'vue';
import {useStore} from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const notificationState = store.state.toastNotification.toastParams;

    function closeNotification() {
      store.commit('toastNotification/closeToastNotification');
    };
  return {
    closeNotification,
    notificationState,
  };
  },
})
</script>

<style scoped>
.notification-wrapper {
  display: flex;
  justify-content: center;
  left: 0;
  padding-top: 92px;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 8;
}

.notification {
  align-items: center;
  background-color: #5A9BE1;
  border-radius: var(--border-radius);
  color: white;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  padding: 0 24px;
  padding-right: 14px;
  pointer-events: all;
  position: sticky;
  top: 24px;
}

.notification.error {
  background-color: #E01926;
}

.notification.success {
  background-color: #18C68C;
}

.close-icon {
  margin-top: 2px;
  margin-left: 12px;
  height: 10px;
}
</style>