import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './core/store';
import './assets/variables.css';
import DefaultButton from './core/components/forms/DefaultButton.vue';
import TextInput from './core/components/forms/TextInput.vue';


const app = createApp(App);

app.component('DefaultButton', DefaultButton);
app.component('TextInput', TextInput);


app.use(store).use(router).mount('#app');
