
import {computed, defineComponent, PropType, ref, toRefs, watch} from 'vue';
import {useRoute} from 'vue-router';

interface Clickable {
  click: () => void;
}

export default defineComponent({
  props: {
    direction: {default: 'right', type: String as PropType<'left' | 'right'>},
    horizontalOffset: {default: 0, type: Number},
    shouldClose: {default: false, type: Boolean},
    verticalOffset: {default: 0, type: Number},
    // Unsure if verticalPositioning is typed properly but this was able to remove the errors --> need to revisit later to confirm
    verticalPositioning: {default: null, type: String as PropType<'top' | 'bottom' | null>},
  },
  setup(props, context) {
    let opened = ref(false);
    const main = ref<HTMLElement | null>(null);
    const container = ref<HTMLElement | null>(null);
    const route = useRoute();
    const shouldClose = toRefs(props).shouldClose

    const isLeft = computed<boolean>(() => {
      return props.direction === 'left';
    });

    const isRight = computed<boolean>(() => {
      return props.direction === 'right';
    });

    const positioningStyle = computed(() => {
      if (props.direction === 'left') {
        if (props.verticalPositioning === 'top') {
          return {right: props.horizontalOffset + 'px'};
        }
        return {
          top: props.verticalOffset + 'px',
          right: props.horizontalOffset + 'px',
        };
      } else {
        return {
          top: props.verticalOffset + 'px',
          right: props.horizontalOffset + 'px',
        };
      }
    });

    function open() {
      opened.value = true;
      const firstDocumentElement = document.firstElementChild as unknown as Clickable;
      firstDocumentElement.click();
      document.addEventListener('click', clickedOutsideDropDown!);
    };

    function close() {
      opened.value = false;
      document.removeEventListener('click', clickedOutsideDropDown!);
    };

    function clickedOutsideDropDown(event: any) {
      if (!main.value!.contains(event.target)) {
        close();
      }
    };
    
    watch(route, () => {
      close();
    });

    watch(shouldClose, () => {
      if (props.shouldClose) {
        close();
      }
    });

    return {
      isLeft,
      isRight,
      main,
      open,
      opened,
      positioningStyle,
    };
  },
})
